import { FC } from 'react';
import { BASE_FRAME_NAMES } from '@constants';
import { Button, FrameShapeSelect } from '@components';
import styles from './FilterRow.module.scss';

interface FilterRowProps {
	withFrameShape?: boolean;
	updateFrameShape?: (f: (typeof BASE_FRAME_NAMES)[number]) => void;
	buttonProps?: Parameters<typeof Button>[0];
}

const FilterRow: FC<FilterRowProps> = ({ withFrameShape = false, updateFrameShape, buttonProps }) => {
	return (
		<div className={styles.container}>
			<div className={withFrameShape ? styles.inner : styles.innerReverse}>
				{withFrameShape && (
					<div className={styles.selectContainer}>
						<FrameShapeSelect callback={updateFrameShape} buttonProps={buttonProps} />
					</div>
				)}
			</div>
		</div>
	);
};

export default FilterRow;
